import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap-css-only/css/bootstrap.min.css'
import 'bootstrap/dist/css/bootstrap.min.css';
// import Clipboard from 'v-clipboard'

//Create application
const app = createApp(App)

//Init Plugins
//app.use(Clipboard)
app.use(router)
app.use(store)

store.dispatch('init_store');

// Mount the app
app.mount('#app');

